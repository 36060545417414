import React from 'react';
import { GatsbyImage, type IGatsbyImageData } from 'gatsby-plugin-image';

const Image = ({ data, className, ...props }: { data: ImgType; className?: string }) => {
  if (!data) return null;
  return (
    <>
      {data.localFile.childImageSharp ? (
        <GatsbyImage
          image={data.localFile.childImageSharp.gatsbyImageData}
          alt={data.altText || 'Obrazek dekoracyjny'}
          className={`img ${className || ''}`}
          {...props}
        />
      ) : (
        <img
          src={data.localFile.publicURL || data.localFile.publicUrl}
          alt={data.altText || 'Obrazek dekoracyjny'}
          className={`img ${className || ''}`}
          {...props}
        />
      )}
    </>
  );
};

export default Image;

export type ImgType = {
  altText: string;
  localFile: {
    publicURL?: string;
    publicUrl?: string;
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};
