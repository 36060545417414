import React, { useEffect, useState } from 'react';
import GlobalStyle from './GlobalStyle';
import Nav from '../components/organisms/Nav';
import Footer from '../components/organisms/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from './Cookies';
import { Script } from 'gatsby';

const Layout = ({ children, location: { pathname } }) => {
  const handleTabKey = (e) => {
    e.key === 'Tab' && document.documentElement.classList.add('tabbing');
  };
  const handleMouseDown = () => {
    document.documentElement.classList.remove('tabbing');
  };

  useEffect(() => {
    document.addEventListener('keydown', handleTabKey);
    window.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('keydown', handleTabKey);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const [isCookiesOpened, setCookiesOpened] = useState(false);

  return (
    <>
      <GlobalStyle />
      <Cookies
        isActive={isCookiesOpened}
        setIsActive={setCookiesOpened}
      />
      {!pathname.includes('formularz') && <Nav />}
      <main
        onDragStart={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        id='main'
      >
        {children}
      </main>
      <Footer setCookiesOpened={setCookiesOpened} />
      <ToastContainer />
      <Script>{`
        (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
        .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
        n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
        (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
        ml('account', '549694');
      `}</Script>
    </>
  );
};

export default Layout;
