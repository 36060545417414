import { createGlobalStyle } from 'styled-components';
import { Clamp } from '../utils/functions';
import './fonts.css';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  :root {
    --primary-100: #FFF3ED;
    --primary-200: #FEE7DC;
    --primary-300: #FDCFB8;
    --primary-400: #F34E3682;
    --primary-500: #E3682F;
    --primary-600: #D4581E;
    --primary-650: #be4f1b;
    --primary-700: #C34810;
    --primary-800: #973406;
    --primary-900: #662000;

    --secondary-100: #F6FAF7;
    --secondary-200: #F3F5F2;
    --secondary-300: #D9E9DD;
    --secondary-400: #C1D6C7;
    --secondary-500: #88B189;
    --secondary-600: #39843C;
    --secondary-700: #4F7C51;
    --secondary-800: #546752;
    --secondary-900: #272928;

    --success-100: #E2FCF4;
    --success-200: #BAF9E6;
    --success-300: #87F3D3;
    --success-400: #57EEC1;
    --success-500: #15E1A4;
    --success-600: #10C790;
    --success-700: #0AA879;
    --success-800: #058D65;
    --success-900: #046F4F;

    --error-100: #FCCFCF;
    --error-200: #F6A4A4;
    --error-300: #F17C7C;
    --error-400: #EC5757;
    --error-500: #E32F2F;
    --error-600: #CE1A1A;
    --error-700: #AD1111;
    --error-800: #970C0C;
    --error-900: #780606;

    --link-color: var(--primary-600);
    --easing: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --pageMargin: 40px;

    @media (max-width: 649px){
      --pageMargin: ${Clamp(16, 40, 40, 'px')};
    }
  }
  body.scrollLock {
    overflow: hidden;
    touch-action: none;
  }
  html, body {
    scroll-padding-top: 111px;
    /* scroll-behavior: smooth; */
  }
  body {
    min-width: 320px;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    background-color: var(--secondary-200);
    color: var(--secondary-800);
    font-size: 18px;
    font-size: ${Clamp(16, 18, 18)};
    line-height: 140%;
  }
  :focus {
    outline: none;
  }
  .tabbing :focus-visible {
    outline: 1px solid var(--secondary-800);
    outline-offset: 5px;
  }
  main,
  .max-width {
    max-width: 1200px;
    width: calc(100% - var(--pageMargin) * 2);
    margin: 0 auto;
    height: 100%;
  }
  svg {
    display: block;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  label {
    display: block;
  }
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  sup {
    font-size: .6em;
    vertical-align: top;
  }
  select {
    cursor: pointer;
  }
  input, textarea, button, select {
    font: inherit;
    color: inherit;
    background-color: transparent;
    appearance: none;
  }
  textarea {
    display: block;
  }
  [disabled] {
    cursor: default;
  }
  summary {
    cursor: pointer;
    list-style: none;
    &::marker,
    &::-webkit-details-marker {
      display: none;
    }
  }
  iframe {
    border: none;
    display: block;
  }

  .cta-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }
  main {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${Clamp(72, 82, 96, 'px')};
    margin-bottom: ${Clamp(72, 82, 96, 'px')};
    padding-top: ${Clamp(72, 82, 96, 'px')};
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;

    color: var(--Secondary-900,#272928);

    em, strong {
      color: var(--secondary-600);
      font-weight: 700;
      font-style: normal;
    }
  }
  h1 {
    font-size: ${Clamp(32, 48, 48)};
    line-height: 116.667%;
    letter-spacing: -0.96px;
    em, strong {
      color: var(--secondary-600);
    }
  }
  h2 {
    font-size: ${Clamp(30, 40, 40)};
    line-height: 120%;
    letter-spacing: -0.8px;
  }
  h3{
    em, strong {
      color: var(--secondary-700) !important;
    }
  }

  h4{
    em, strong {
      color: var(--secondary-700) !important;
    }
  }

  .sec-wo-margin {
    scroll-padding-top: ${Clamp(68, 72, 76, 'px')};
    position: relative;
    &::before {
      content: '';
      width: 100vw;
      height: 100%;
      background-color: inherit;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      z-index: -1;
    }
  }
  .link {
    position: relative;
    display: inline-block;
    color: var(--secondary-800);
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--secondary-800);
      transition: transform .4s var(--easing);
    }
    &:hover::before {
      transform: scaleX(.9);
    }
    &:active::before {
      transform: scaleX(.8);
    }
  }


  .wp-block-button__link{
    padding: 16px ${Clamp(24, 32, 48, 'px')};
    line-height: 1;
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 50px !important;
    &[disabled] {
      cursor: default;
      pointer-events: none;
    }
    @media (max-width: 499px){
      width: 100%;
    }
    color: var(--secondary-100) !important;
    transition: background-color .3s;
    background-color: var(--primary-650) !important;
    &:hover {
      background-color: var(--primary-600) !important;
    }
    &:active {
      background-color: var(--primary-500) !important;
    }
  }

  .has-text-align-center{
    text-align: center;
  }
`;

export default GlobalStyle;
