import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Clamp } from '../../utils/functions';
import Button from '../atoms/Button';
import Image from '../atoms/Image';

const Nav = () => {
  const [navOpened, setNavOpened] = useState(false);

  const {
    wpPage: { header },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      wpPage(id: { eq: "cG9zdDoyOQ==" }) {
        header {
          logo {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          logoMobile {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          links {
            mobileOnly
            link {
              title
              url
            }
          }
          cta {
            url
            title
          }
        }
      }
    }
  `);

  return (
    <>
      <WrapperSkipToMainContent href='#main'>Przejdź do głównej treści</WrapperSkipToMainContent>
      <WrapperNav aria-expanded={navOpened}>
        <div className='max-width'>
          <Link
            to='/'
            aria-label='Strona główna'
            className='logo'
            onClick={() => setNavOpened(false)}
          >
            <Image className='desctop' data={header.logo}/>
            <Image className='mobile' data={header.logoMobile}/>
          </Link>
          <ul className='links'>
            {header.links.map((link, index) => (
              <li
                key={index}
                className={link.mobileOnly ? 'mobileItem' : ''}
              >
                <Link
                  partiallyActive={index>0}
                  activeClassName='active'
                  to={link.link.url}
                  onClick={() => setNavOpened(false)}
                >
                  {link.link.title}
                </Link>
              </li>
            ))}
          </ul>
          <Button
            to={header.cta.url}
            theme='primary'
            onClick={() => setNavOpened(false)}
          >
            {header.cta.title}
          </Button>
          <button
            className='nav-btn'
            onClick={() => setNavOpened(!navOpened)}
            aria-label={`${navOpened ? 'Ukryj' : 'Pokaż'} nawigację`}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </WrapperNav>
      <WrapperOverlay
        className='overlay'
        aria-hidden='true'
        onClick={() => setNavOpened(false)}
      ></WrapperOverlay>
    </>
  );
};

const WrapperNav = styled.nav`
  height: ${Clamp(68, 72, 76, 'px')};
  background-color: var(--secondary-200);
  position: sticky;
  top: 0;
  z-index: 9;

  .logo {
    .mobile {
      display: none;
    }

    @media (max-width: 480px) {
      .mobile {
        display: block;
      }

      .desctop {
        display: none;
      }
    }
  }
  > .max-width {
    border-bottom: 1px solid var(--secondary-400);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .links {
    display: flex;
    align-items: center;
    gap: 32px ${Clamp(24, 24, 38, 'px')};
    list-style-type: none;
    font-size: 1rem;
    a {
      transition: color 0.4s;
      &:hover {
        color: var(--secondary-900);
      }
      &.active {
        font-weight: 700;
      }
    }
  }
  .nav-btn {
    display: none;
    gap: 8px;
    padding: 13px 7px;
    margin-right: -7px;
    span {
      display: block;
      width: 34px;
      height: 2px;
      background-color: var(--secondary-700);
      border-radius: 4px;
      transition: transform 0.5s var(--easing);
    }
  }
  @media (min-width: 941px) {
    .mobileItem {
      display: none;
    }
  }
  @media (max-width: 940px) {
    .links {
      background-color: var(--secondary-300);
      position: fixed;
      right: 0;
      max-width: calc(100% - 55px);
      top: ${Clamp(68, 72, 76, 'px')};
      height: calc(100% - ${Clamp(68, 72, 76, 'px')});
      flex-direction: column;
      padding: 55px ${Clamp(34, 48, 64, 'px')};
      align-items: flex-start;
      font-weight: 700;
      transform: translateX(100%);
      visibility: hidden;
      transition:
        transform 0.5s var(--easing),
        visibility 0.5s;
      .mobileItem:nth-last-of-type(2) {
        border-top: 1px solid var(--secondary-400);
        padding-top: 32px;
      }
    }
    &[aria-expanded='true'] {
      .links {
        transform: translateX(0);
        visibility: visible;
      }
      .nav-btn {
        span {
          &:nth-child(1) {
            transform: translateY(10px) rotate(135deg);
          }
          &:nth-child(2) {
            transform: scaleX(0);
          }
          &:nth-child(3) {
            transform: translateY(-10px) rotate(-135deg);
          }
        }
      }
      + .overlay {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .nav-btn {
      display: grid;
    }
  }
  @media (max-width: 499px) {
    .logo {
      svg {
        width: 41px;
      }
      .logotype {
        display: none;
      }
    }
    .cta {
      width: 100%;
      margin: 0 32px;
    }
  }
`;
const WrapperOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.62);
  z-index: 8;
  transition: opacity 0.5s var(--easing);
  opacity: 0;
  pointer-events: none;
`;

const WrapperSkipToMainContent = styled.a`
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 13px;
  background-color: var(--secondary-300);
  &:focus-visible {
    opacity: 1;
    pointer-events: auto;
  }
`;

export default Nav;
