exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audyt-js": () => import("./../../../src/pages/audyt.js" /* webpackChunkName: "component---src-pages-audyt-js" */),
  "component---src-pages-blad-js": () => import("./../../../src/pages/blad.js" /* webpackChunkName: "component---src-pages-blad-js" */),
  "component---src-pages-blad-platnosci-js": () => import("./../../../src/pages/blad-platnosci.js" /* webpackChunkName: "component---src-pages-blad-platnosci-js" */),
  "component---src-pages-dziekujemy-js": () => import("./../../../src/pages/dziekujemy.js" /* webpackChunkName: "component---src-pages-dziekujemy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-formularz-audyt-czyste-powietrze-dziekujemy-js": () => import("./../../../src/pages/formularz-audyt-czyste-powietrze/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-audyt-czyste-powietrze-dziekujemy-js" */),
  "component---src-pages-formularz-audyt-czyste-powietrze-index-js": () => import("./../../../src/pages/formularz-audyt-czyste-powietrze/index.js" /* webpackChunkName: "component---src-pages-formularz-audyt-czyste-powietrze-index-js" */),
  "component---src-pages-formularz-audyt-efektywnosci-energetycznej-dziekujemy-js": () => import("./../../../src/pages/formularz-audyt-efektywnosci-energetycznej/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-audyt-efektywnosci-energetycznej-dziekujemy-js" */),
  "component---src-pages-formularz-audyt-efektywnosci-energetycznej-index-js": () => import("./../../../src/pages/formularz-audyt-efektywnosci-energetycznej/index.js" /* webpackChunkName: "component---src-pages-formularz-audyt-efektywnosci-energetycznej-index-js" */),
  "component---src-pages-formularz-audyt-przedsiebiorstwa-dziekujemy-js": () => import("./../../../src/pages/formularz-audyt-przedsiebiorstwa/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-audyt-przedsiebiorstwa-dziekujemy-js" */),
  "component---src-pages-formularz-audyt-przedsiebiorstwa-index-js": () => import("./../../../src/pages/formularz-audyt-przedsiebiorstwa/index.js" /* webpackChunkName: "component---src-pages-formularz-audyt-przedsiebiorstwa-index-js" */),
  "component---src-pages-formularz-audyt-remontowy-dziekujemy-js": () => import("./../../../src/pages/formularz-audyt-remontowy/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-audyt-remontowy-dziekujemy-js" */),
  "component---src-pages-formularz-audyt-remontowy-index-js": () => import("./../../../src/pages/formularz-audyt-remontowy/index.js" /* webpackChunkName: "component---src-pages-formularz-audyt-remontowy-index-js" */),
  "component---src-pages-formularz-budynek-dziekujemy-js": () => import("./../../../src/pages/formularz-budynek/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-budynek-dziekujemy-js" */),
  "component---src-pages-formularz-budynek-index-js": () => import("./../../../src/pages/formularz-budynek/index.js" /* webpackChunkName: "component---src-pages-formularz-budynek-index-js" */),
  "component---src-pages-formularz-dom-dziekujemy-js": () => import("./../../../src/pages/formularz-dom/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-dom-dziekujemy-js" */),
  "component---src-pages-formularz-dom-index-js": () => import("./../../../src/pages/formularz-dom/index.js" /* webpackChunkName: "component---src-pages-formularz-dom-index-js" */),
  "component---src-pages-formularz-dom-skrocony-dziekujemy-js": () => import("./../../../src/pages/formularz-dom-skrocony/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-dom-skrocony-dziekujemy-js" */),
  "component---src-pages-formularz-dom-skrocony-index-js": () => import("./../../../src/pages/formularz-dom-skrocony/index.js" /* webpackChunkName: "component---src-pages-formularz-dom-skrocony-index-js" */),
  "component---src-pages-formularz-lokal-dziekujemy-js": () => import("./../../../src/pages/formularz-lokal/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-lokal-dziekujemy-js" */),
  "component---src-pages-formularz-lokal-index-js": () => import("./../../../src/pages/formularz-lokal/index.js" /* webpackChunkName: "component---src-pages-formularz-lokal-index-js" */),
  "component---src-pages-formularz-lokal-skrocony-dziekujemy-js": () => import("./../../../src/pages/formularz-lokal-skrocony/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-lokal-skrocony-dziekujemy-js" */),
  "component---src-pages-formularz-lokal-skrocony-index-js": () => import("./../../../src/pages/formularz-lokal-skrocony/index.js" /* webpackChunkName: "component---src-pages-formularz-lokal-skrocony-index-js" */),
  "component---src-pages-formularz-mieszkanie-dziekujemy-js": () => import("./../../../src/pages/formularz-mieszkanie/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-mieszkanie-dziekujemy-js" */),
  "component---src-pages-formularz-mieszkanie-index-js": () => import("./../../../src/pages/formularz-mieszkanie/index.js" /* webpackChunkName: "component---src-pages-formularz-mieszkanie-index-js" */),
  "component---src-pages-formularz-mieszkanie-skrocony-dziekujemy-js": () => import("./../../../src/pages/formularz-mieszkanie-skrocony/dziekujemy.js" /* webpackChunkName: "component---src-pages-formularz-mieszkanie-skrocony-dziekujemy-js" */),
  "component---src-pages-formularz-mieszkanie-skrocony-index-js": () => import("./../../../src/pages/formularz-mieszkanie-skrocony/index.js" /* webpackChunkName: "component---src-pages-formularz-mieszkanie-skrocony-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/wspolpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-cooperation-article-js": () => import("./../../../src/templates/cooperation-article.js" /* webpackChunkName: "component---src-templates-cooperation-article-js" */),
  "component---src-templates-video-archive-js": () => import("./../../../src/templates/video-archive.js" /* webpackChunkName: "component---src-templates-video-archive-js" */),
  "component---src-templates-video-article-js": () => import("./../../../src/templates/video-article.js" /* webpackChunkName: "component---src-templates-video-article-js" */),
  "component---src-templates-video-category-js": () => import("./../../../src/templates/video-category.js" /* webpackChunkName: "component---src-templates-video-category-js" */)
}

