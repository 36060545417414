import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Clamp } from '../../utils/functions';

const Button = ({
  data,
  theme = 'primary',
  children,
  to,
  className,
  ...props
}: {
  data?: {
    theme?: 'primary' | 'secondary';
    url: string;
    title: string;
  };
  theme?: 'primary' | 'secondary';
  children?: React.ReactNode;
  to?: string;
  className?: string;
}) => {
  if (data) {
    theme = data.theme || theme;
    to = data.url;
    children = data.title;
  }
  const isExternal = to?.startsWith('https://');

  const commonProps = {
    className: `cta ${className}`,
    'data-theme': theme,
    ...props,
  };

  return to ? (
    isExternal ? (
      <StyledAnchor
        as='a'
        href={to}
        target='_blank'
        rel='noreferrer'
        {...commonProps}
      >
        {children}
      </StyledAnchor>
    ) : (
      <StyledAnchor
        as={Link}
        to={to}
        {...commonProps}
      >
        {children}
      </StyledAnchor>
    )
  ) : (
    <StyledAnchor
      as='button'
      {...commonProps}
    >
      {children}
    </StyledAnchor>
  );
};

const StyledAnchor = styled.a`
  padding: 16px ${Clamp(24, 32, 48, 'px')};
  line-height: 1;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 50px;
  &[disabled] {
    cursor: default;
    pointer-events: none;
  }
  @media (max-width: 499px) {
    width: 100%;
  }
  color: var(--secondary-100);
  transition: background-color 0.3s;
  &[data-theme='primary'] {
    background-color: var(--primary-650);
    &:hover {
      background-color: var(--primary-600);
    }
    &:active {
      background-color: var(--primary-500);
    }
  }
  &[data-theme='secondary'] {
    background-color: var(--secondary-700);
    &:hover {
      background-color: var(--secondary-800);
    }
    &:active {
      background-color: var(--secondary-900);
    }
  }
`;

export default Button;

export type ButtonType = {
  data?: {
    theme?: 'primary' | 'secondary';
    url: string;
    title: string;
  };
  theme?: 'primary' | 'secondary';
  children: React.ReactNode;
  to?: string;
  className?: string;
};
